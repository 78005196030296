import toastr from 'toastr';
import Chart from 'chart.js';
import xss from 'xss';
import selector from './tableSelectors';
import Breakpoints from '../utilities/breakpoints';
export default class ZaFaTable {
    constructor(Storage, PdfPrinter) {
        this.isLoading = true;
        this.highlightedId = null;
        this.waitForRefresh = -1;
        this.initHeadingRowCollapseState = () => {
            const headingRows = document.querySelectorAll(`${selector.ROW}[data-structure="heading1"]`);
            for (const headingRow of Array.from(headingRows)) {
                headingRow.dataset.collapsed = 'false';
                const identifier = headingRow.dataset.identifier;
                const childrenRows = document.querySelectorAll(`[data-identifier-parent="${identifier}"]`);
                for (const childRow of Array.from(childrenRows)) {
                    childRow.dataset.collapsed = 'false';
                }
                const collapseButton = headingRow.querySelector(selector.TABLE_ROW_COLLAPSE_TRIGGER);
                collapseButton.dataset.collapsed = 'false';
            }
        };
        this.initScrollPos = () => {
            const position = this.getUrlParameter('scroll', null);
            if (position !== null) {
                this.$window.scrollTop(position);
            }
        };
        this.initNormalRowCollapseState = () => {
            $(`${selector.ROW}:not([data-structure="head"])[data-collapsed="false"]`).each((index, row) => {
                const $tableRow = $(row);
                const structure = $tableRow.attr('data-structure');
                const id = $tableRow.attr('data-id');
                let hasUncollapsedChild = false;
                $tableRow
                    .nextUntil($(`${selector.ROW}[data-structure="${structure}"]`), `${selector.ROW}[data-parent="${id}"]`)
                    .each((idx, elm) => {
                    if ($(elm).attr('data-collapsed') === 'false') {
                        hasUncollapsedChild = true;
                    }
                });
                if (hasUncollapsedChild) {
                    $tableRow.attr('data-children-collapsed', 'false');
                    $tableRow
                        .find(selector.TABLE_ROW_COLLAPSE_TRIGGER)
                        .attr('data-collapsed', 'false');
                }
            });
        };
        this.initLocalConfig = ignoreLocalStorage => {
            const years = xss(this.getUrlParameter('years', null));
            let searchRowIdentifier = xss(this.getUrlParameter('rowIdentifier', null));
            this.highlightedId = xss(this.getUrlParameter('highlighted', null));
            let config = null;
            if (!ignoreLocalStorage) {
                config = this.Storage.get(this.sheetId, false);
            }
            if (config === null) {
                config = this.generateCurrentVisibleConfig();
            }
            if (typeof years === 'string' && years !== '') {
                if (years.length) {
                    config.years = years.split(',');
                }
                else {
                    config.years = [];
                }
            }
            if (typeof config.years !== 'object') {
                config.years = [];
            }
            if (searchRowIdentifier === '') {
                if (this.highlightedId === '') {
                    this.showTableCellsByConfig(config, null);
                    this.showTableRowsByConfig(config, null);
                    return;
                }
                searchRowIdentifier = this.highlightedId;
            }
            const $searchRow = $(`${selector.ROW}[data-identifier="${searchRowIdentifier}"]`);
            this.highlightedId = searchRowIdentifier;
            config.row = searchRowIdentifier;
            let searchCellId = xss(this.getUrlParameter('cell', null));
            searchCellId = this.isValidCellCollPattern(searchCellId) ? searchCellId : null;
            let searchColId = xss(this.getUrlParameter('col', null)); // This one is optional
            searchColId = this.isValidCellCollPattern(searchColId) ? searchColId : null;
            const searchFunctionId = xss(this.getUrlParameter('function', null));
            if (searchCellId === null && searchFunctionId === null) {
                this.showTableCellsByConfig(config, this.generateQueryString);
                this.showTableRowsByConfig(config, this.generateQueryString);
                return;
            }
            if (searchCellId !== null && searchColId !== null) {
                const $searchCell = $searchRow.find(`${selector.CELL}[data-id="${searchCellId}"][data-col-id="${searchColId}"]`);
                if ($searchCell.length) {
                    const searchCellSortOrder = $searchCell.attr('data-sort-order');
                    if (typeof searchCellSortOrder !== 'undefined') {
                        config.years.push(searchCellSortOrder);
                    }
                }
            }
            if (searchFunctionId !== null) {
                const $inactiveTriggerFunction = $searchRow.find(`${selector.FUNCTION_TRIGGER}[data-active="false"]`);
                if ($inactiveTriggerFunction.length) {
                    setTimeout(() => {
                        $inactiveTriggerFunction.trigger('click');
                    }, 1000);
                }
            }
            this.showTableCellsByConfig(config, this.generateQueryString);
            this.showTableRowsByConfig(config, this.generateQueryString);
        };
        this.isValidCellCollPattern = (input) => {
            const regex = /^(?:\d{1,3})(?:-(?:\d{1,3}|meta))?$/;
            return regex.test(input);
        };
        this.getMobileYearSortOrders = () => {
            this.setEdited();
            // Only the columns below should be shown on mobile (+ the helper column "1")
            const desiredYearColumns = [
                (new Date().getFullYear() - 1).toString(),
                (new Date().getFullYear() - 2).toString(),
                '%', // Delta
            ];
            const yearButtons = document.querySelectorAll('.plf-edit-mobile-wrapper .plf-year-handler');
            const desiredSortOrders = [];
            yearButtons.forEach(yearButton => {
                const title = yearButton.getAttribute('title');
                const isDesiredColumn = desiredYearColumns.some(column => title.includes(column));
                if (isDesiredColumn) {
                    desiredSortOrders.push(yearButton.dataset.sortOrder);
                }
            });
            desiredSortOrders.push('1');
            return desiredSortOrders;
        };
        /**
       * @param {{row, years}} config
       * @param {function|null} [callback=]
       * @private
       */
        this.showTableCellsByConfig = (config, callback) => {
            this.expandParentsRecursive(config.row, 0);
            $(`${selector.CELL}[data-selected="true"]`).attr('data-selected', 'false');
            $(`${selector.YEAR_TRIGGER}[data-selected="true"]`).attr('data-selected', 'false');
            if (config.years.length) {
                config.years = config.years.filter((value, index, self) => self.indexOf(value) === index);
                $.each(config.years, (i, val) => {
                    $(`${selector.CELL}[data-sort-order="${val}"]`).attr('data-selected', 'true');
                    $(`${selector.YEAR_TRIGGER}[data-sort-order="${val}"]`).attr('data-selected', 'true');
                });
            }
            if (typeof callback === 'function') {
                callback();
            }
        };
        /**
       * @param {{row, years}} config
       * @param {function|null} [callback=]
       * @private
       */
        this.showTableRowsByConfig = (config, callback) => {
            if (config.rows.length) {
                config.rows = config.rows.filter((value, index, self) => self.indexOf(value) === index);
                $.each(config.rows, (i, val) => {
                    const $row = $(`${selector.ROW}[data-id="${val}"]`);
                    $row.attr('data-collapsed', 'false');
                    this.expandParentsRecursive($row, 0);
                });
            }
            if (typeof callback === 'function') {
                callback();
            }
        };
        this.expandAllChildren = parentRowIdentifier => {
            $(`${selector.ROW}[data-identifier-parent="${parentRowIdentifier}"]`)
                .each((index, row) => row.setAttribute('data-collapsed', 'false'));
        };
        /**
       * @param {string} rowIdentifier
       * @param {number} iteration
       * @private
       */
        this.expandParentsRecursive = (rowIdentifier, iteration) => {
            iteration = typeof iteration !== 'undefined' ? iteration : 0;
            if (typeof rowIdentifier !== 'string' || rowIdentifier === '') {
                return;
            }
            const $currentRow = $(`${selector.ROW}[data-identifier="${rowIdentifier}"]`);
            const parentRowIdentifier = $currentRow.attr('data-identifier-parent');
            this.expandParentsRecursive(parentRowIdentifier, iteration + 1);
            this.expandAllChildren(parentRowIdentifier);
            // this is to not change the appearance of the initially selected row
            if (iteration > 0) {
                $currentRow.attr('data-children-collapsed', 'false');
                $currentRow
                    .find(selector.TABLE_ROW_COLLAPSE_TRIGGER)
                    .attr('data-collapsed', 'false');
            }
        };
        /**
       * @param {number} nbr
       * @param {number} [nbrDigits=2]
       * @return {string}
       * @private
       */
        this.formatLocaleNumber = (nbr, nbrDigits) => {
            const digitFactor = Math.pow(10, nbrDigits);
            nbr = Math.round(nbr * digitFactor) / digitFactor;
            if (!nbr.toLocaleString) {
                return `${nbr}`;
            }
            if (this.language.includes('-')) {
                return nbr.toLocaleString(this.language);
            }
            return nbr.toLocaleString(`${this.language}-CH`);
        };
        /**
       * @param {{ dataset, labelStr, maxY, minY, steps }} config
       * @returns {object}
       * @private
       */
        this.generateSBBChartConfig = config => ({
            type: config.type,
            data: config.dataset,
            options: {
                title: {
                    display: true,
                    text: config.labelStr,
                    fontFamily: '"SBB", Helvetica, Arial, sans-serif',
                    fontColor: '#000',
                    fontStyle: 'normal',
                    fontSize: 14,
                    position: 'left',
                },
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            barThickness: 30,
                            ticks: {
                                fontFamily: '"SBB", Helvetica, Arial, sans-serif',
                                fontColor: '#000',
                                fontStyle: 'normal',
                                fontSize: 14,
                            },
                            gridLines: {
                                display: false,
                                drawBorder: false,
                                drawOnChartArea: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                max: config.maxY,
                                min: config.minY,
                                stepSize: config.steps,
                                fontFamily: '"SBB", Helvetica, Arial, sans-serif',
                                fontColor: '#000',
                                fontStyle: 'normal',
                                fontSize: 14,
                                callback: label => {
                                    if (typeof label !== 'number') {
                                        return label;
                                    }
                                    return this.formatLocaleNumber(label, 2);
                                },
                            },
                            gridLines: {
                                color: 'rgba(215,215,215, 1)',
                                lineWidth: 1,
                                zeroLineColor: 'rgba(186,186,186,1)',
                                zeroLineWidth: 2,
                                tickMarkLength: 0,
                            },
                        },
                    ],
                },
                tooltips: {
                    backgroundColor: '#E5E5E5',
                    displayColors: false,
                    cornerRadius: 0,
                    bodySpacing: 0,
                    xPadding: 12,
                    yPadding: 6,
                    titleFontSize: 0,
                    titleSpacing: 0,
                    titleMarginBottom: 0,
                    bodyFontColor: '#000',
                    bodyFontFamily: '"SBB", Helvetica, Arial, sans-serif',
                    bodyFontSize: 14,
                    bodyFontStyle: 'normal',
                    callbacks: {
                        label: tooltipItems => `${this.formatLocaleNumber(tooltipItems.yLabel, 2)} ${config.labelStr}`,
                    },
                },
            },
        });
        /**
       * @param {object} dataset
       * @param {array} colIds
       * @return {object}
       * @private
       */
        this.addLabelsToDatasetByColIds = (dataset, colIds) => {
            dataset.labels = [...colIds];
            return dataset;
        };
        /**
       * @param {object} $elem
       * @param {string} type
       * @private
       */
        this.generateSBBChart = ($elem, type) => {
            const dataset = this.addLabelsToDatasetByColIds(JSON.parse($elem.attr('data-chart')), JSON.parse($elem.attr('data-col-ids')));
            const steps = parseInt($elem.attr('data-steps'), 10);
            const maxY = parseInt($elem.attr('data-max'), 10);
            const minY = parseInt($elem.attr('data-min'), 10);
            const context = $elem[0].getContext('2d');
            const labelStr = $elem
                .closest(selector.META_ROW)
                .prev()
                .find('*[data-type="unit"] > span')
                .text()
                .replace(/[\n\t\r]+/g, '') // Remove newlines, tabs, and carriage returns
                .replace(/\s/g, ''); // Remove spaces
            // Convert String array to number array
            dataset.datasets[0].data = dataset.datasets[0].data.map(Number);
            return new Chart(context, this.generateSBBChartConfig({
                type,
                dataset,
                labelStr,
                maxY,
                minY,
                steps,
            }));
        };
        /**
       * @param {object} $elem
       */
        this.initBarChart = $elem => this.generateSBBChart($elem, 'bar');
        /**
       * @param {object} $elem
       */
        this.initLineChart = $elem => this.generateSBBChart($elem, 'line');
        // *****************************************************************************
        // functions
        // *****************************************************************************
        this.addTableHeaders = () => {
            const $head = $(`${selector.ROW}[data-structure="head"]`);
            let currentHeight = $('.js-table-title').outerHeight(true);
            const PAGE_SPACING = 20;
            const MIN_FOOTER_HEIGHT = 65;
            const MIN_HEADER_HEIGHT = 65;
            const MAX_PAGE_HEIGHT = (1024 / 793) * 1122
                - PAGE_SPACING
                - MIN_FOOTER_HEIGHT
                - MIN_HEADER_HEIGHT;
            this.$table
                .find(`${selector.ROW}:not([data-collapsed="true"])`)
                .each((index, row) => {
                const $tableRow = $(row);
                const height = $tableRow.height();
                const tempHeight = height + currentHeight;
                if (tempHeight > MAX_PAGE_HEIGHT) {
                    $head.clone().addClass('page-break-before').insertAfter($tableRow);
                    currentHeight = height;
                }
                else if (tempHeight === MAX_PAGE_HEIGHT) {
                    currentHeight = 0;
                    $head.clone().addClass('page-break-before').insertAfter($tableRow);
                }
                else {
                    currentHeight += height;
                }
            });
        };
        this.markLastVisibleCell = () => {
            $(selector.ROW).each((index, element) => {
                this.$table.attr('data-loading', 'true');
                $(element)
                    .find(`${selector.CELL}:visible:last`)
                    .attr('data-last-visible', 'true');
                setTimeout(() => {
                    this.$table.attr('data-loading', 'false');
                }, 150);
            });
        };
        this.getUrlParameters = () => {
            const result = {};
            const url = window.location.search.substring(1);
            const urlParams = url.split('&');
            let urlParamSplitIndex;
            for (const i in urlParams) {
                if (Object.prototype.hasOwnProperty.call(urlParams, i)) {
                    urlParamSplitIndex = urlParams[i].indexOf('=');
                    if (urlParamSplitIndex < 0) {
                        urlParamSplitIndex = urlParams[i].length;
                    }
                    result[decodeURIComponent(urlParams[i].substring(0, urlParamSplitIndex))] = urlParamSplitIndex <= urlParams[i].length
                        ? decodeURIComponent(urlParams[i].substring(urlParamSplitIndex + 1))
                        : null;
                }
            }
            return result;
        };
        this.getUrlParameter = (sParam, defaultResult) => {
            const parameters = this.getUrlParameters();
            if (!Object.prototype.hasOwnProperty.call(parameters, sParam)) {
                return defaultResult;
            }
            return parameters[sParam];
        };
        this.setDeepLink = event => {
            event.stopPropagation();
            const $this = $(event.currentTarget);
            const $parentRow = $this.closest('.plf-zafa-row');
            const $parentRowId = $parentRow.attr('data-id');
            this.highlightedId = $parentRow.attr('data-identifier');
            const $metaRow = $parentRow.siblings(`[data-id=${$parentRowId}-meta]`);
            this.generateQueryString(false);
            const croppedUrl = this.generateCroppedUrl(window.location.href, this.highlightedId);
            const $input = $metaRow.find('.plf-zafa-deeplink-input');
            $input.attr('value', croppedUrl);
        };
        this.generateCroppedUrl = (url, identifier) => {
            const base = url.split('?')[0];
            return `${base}?highlighted=${identifier}`;
        };
        this.highlightVisibleOddRows = () => {
            let i = 0;
            $(`${selector.ROW}:not([data-collapsed="true"])`).each((index, element) => {
                const $self = $(element);
                if ($self.attr('data-structure') === 'head'
                    || $self.attr('data-structure') === 'heading1') {
                    i = 0;
                    return;
                }
                if (i % 2 === 0) {
                    $self.removeClass('odd');
                }
                else {
                    $self.addClass('odd');
                }
                i++;
            });
        };
        /**
       * @param {boolean} newState
       */
        this.refreshTableLoadingState = newState => {
            if (typeof newState !== 'undefined') {
                this.isLoading = newState;
            }
            this.$table.attr('data-loading', `${this.isLoading}`);
        };
        this.resetAbsoluteStyles = () => {
            $(selector.ABSOLUTE_CELL).removeAttr('style');
            $(selector.SCROLL_SHADOW).removeAttr('style');
            $('.generated-absolute-spacer').remove();
        };
        this.setTableStyleAndPositioningDependingOnScreenSize = () => {
            this.$table.removeClass('no-overflow');
            this.refreshTableLoadingState(true);
            $(selector.TABLE_WRAPPER).scrollLeft(0);
            $(selector.TABLE_WRAPPER).attr('data-scroll', 'false');
            this.resetAbsoluteStyles();
            $(`${selector.FUNCTION_TRIGGER}[data-active="true"]`).trigger('click');
            const margin = parseInt($(selector.TABLE_WRAPPER).css('margin-left'), 10);
            let visibleWidth = -margin;
            $(`${selector.ROW}:first-of-type ${selector.CELL}:not([data-selected="false"],${selector.ABSOLUTE_CELL}, .hidden-small), ${selector.ROW}:first-of-type ${selector.ABSOLUTE_CELL}`)
                .each((index, element) => {
                visibleWidth += $(element).width();
            });
            if (this.$window.width() <= Breakpoints.breakpointMobile
                && this.$window.width() < visibleWidth) {
                let offset = 0;
                this.$table.css({
                    'min-width': `${$(selector.FLUID_CONTAINER).width()}px`,
                });
                $(selector.TABLE_WRAPPER).attr('data-scroll', 'true');
                $(selector.ABSOLUTE_CELL).each((index, cell) => {
                    const $tableCell = $(cell);
                    $tableCell.removeAttr('style');
                    $tableCell.css({
                        left: `${$tableCell.position().left}px`,
                        width: $tableCell.width(),
                    });
                });
                $(selector.ABSOLUTE_CELL).css({
                    position: 'absolute',
                });
                $(`${selector.ROW}:first-of-type ${selector.ABSOLUTE_CELL}`)
                    // eslint-disable-next-line no-return-assign
                    .each((index, row) => {
                    const outerWidth = $(row).outerWidth();
                    offset += (outerWidth === undefined ? 0 : outerWidth);
                });
                $(selector.ROW).each((index, row) => {
                    $(row).prepend(`<td class="generated-absolute-spacer" style="display: table-cell; width:${offset}px; min-width:${offset}px;"></td>`);
                    $(row).append(`<td class="generated-absolute-spacer" style="display: table-cell; width:${-margin}px; min-width:${-margin}px;"></td>`);
                });
                const top = $(selector.TABLE_WRAPPER).position().top;
                $(selector.SCROLL_SHADOW).css({
                    top: $(`${selector.EDIT_CBX}:checked`)
                        ? top - $(selector.EDIT_WRAPPER).height()
                        : top,
                    left: `${offset + margin - 5}px`,
                });
            }
            else {
                this.$table.addClass('no-overflow');
            }
            return setTimeout(() => {
                this.refreshTableLoadingState(false);
            }, 400);
        };
        /**
       * @param {Array.<Array.<string>>} array
       * @param {string} [separatorCol=';']
       * @param {string} [separatorRow='\n']
       * @return {string | null}
       * @private
       */
        this.getCSVContentFromArray = (array, separatorCol, separatorRow) => {
            if (typeof separatorCol !== 'string') {
                separatorCol = ';';
            }
            if (typeof separatorRow !== 'string') {
                separatorRow = '\n';
            }
            if (typeof array !== 'object'
                || !array.length
                || typeof array[0] !== 'object') {
                console.error('getCSVContentFromArray does not support the given value as array-parameter!');
                return null;
            }
            const lines = [];
            for (const i in array) {
                if (Object.prototype.hasOwnProperty.call(array, i)) {
                    lines.push(array[i].join(separatorCol));
                }
            }
            return lines.join(separatorRow);
        };
        /**
       * @return {{rows: Array, years: Array}}
       * @private
       */
        this.generateCurrentVisibleConfig = () => {
            if (this.$window.width() < Breakpoints.breakpointMobile) {
                return {
                    rows: [],
                    years: this.getMobileYearSortOrders(),
                };
            }
            const visibleRows = [];
            const visibleYears = [];
            const cells = document.querySelectorAll(`${selector.ROW}:first-of-type ${selector.CELL}[data-selected="true"]`);
            for (let i = 0; i < cells.length; i++) {
                visibleYears.push(cells[i].getAttribute('data-sort-order'));
            }
            return {
                rows: visibleRows,
                years: visibleYears,
            };
        };
        this.generateQueryString = historyReplaceState => {
            const queryStringObj = this.getUrlParameters();
            const currentVisibleConfig = this.generateCurrentVisibleConfig();
            if (currentVisibleConfig.rows.length) {
                queryStringObj.rows = currentVisibleConfig.rows;
            }
            if (currentVisibleConfig.years.length) {
                queryStringObj.years = currentVisibleConfig.years;
            }
            queryStringObj.scroll = this.$window.scrollTop();
            if (this.highlightedId !== null) {
                queryStringObj.highlighted = this.highlightedId;
            }
            const queryStringParts = [];
            let paramValue;
            for (const paramKey in queryStringObj) {
                if (Object.prototype.hasOwnProperty.call(queryStringObj, paramKey)
                    || !paramKey.length) {
                    paramValue = encodeURIComponent(queryStringObj[paramKey]);
                    paramValue = paramValue.replace(/%2C/g, ',');
                    queryStringParts.push(`${encodeURIComponent(paramKey)}=${paramValue}`);
                }
            }
            const queryString = queryStringParts.join('&');
            const url = window.location.pathname + (queryString.length ? `?${queryString}` : '');
            if (historyReplaceState) {
                window.history.replaceState({ path: url }, '', url);
            }
            else {
                window.history.pushState({ path: url }, '', url);
            }
        };
        this.setRefreshIntervall = () => {
            setInterval(() => {
                if (this.waitForRefresh < 0) {
                    return;
                }
                if (this.waitForRefresh < 3) {
                    this.waitForRefresh++;
                    return;
                }
                this.waitForRefresh = -1;
                this.generateQueryString(true);
            }, 100);
        };
        this.triggerInitialTableHeadFixing = () => {
            const isPdf = this.getUrlParameter('printpdf', false);
            if (!isPdf) {
                this.$window.trigger('fixTableHead');
            }
        };
        // *****************************************************************************
        // events
        // *****************************************************************************
        this.onClickRowDownload = event => {
            event.preventDefault();
            event.stopPropagation();
            const $self = $(event.currentTarget);
            const downloadUrl = $self.attr('data-download-url');
            if (typeof downloadUrl !== 'string') {
                console.error('The element has no valid data-download-url attribute!');
                return;
            }
            const colIds = $self.attr('data-col-ids');
            if (typeof colIds !== 'string') {
                console.error('The element has no valid data-col-ids attribute!');
                return;
            }
            const $clsRow = $self.closest(selector.ROW);
            const $clsRowCells = $clsRow.find(selector.CELL);
            const contentLine = {};
            $clsRowCells.each((index, element) => {
                const $clsRowCell = $(element);
                const sortOrder = $clsRowCell.attr('data-sort-order');
                if (typeof sortOrder === 'undefined') {
                    return;
                }
                const dataColId = $clsRowCell.attr('data-col-id');
                if (typeof dataColId !== 'undefined') {
                    const colId = parseInt(dataColId, 10);
                    if (Number.isNaN(colId) && colIds.indexOf(dataColId) < 0) {
                        return;
                    }
                }
                let clsRowCellContent = $clsRowCell.attr('data-cell-value');
                if (typeof clsRowCellContent === 'undefined') {
                    clsRowCellContent = $clsRowCell.text().trim();
                }
                if (typeof clsRowCellContent !== 'string') {
                    clsRowCellContent = `${clsRowCellContent}`;
                }
                if (clsRowCellContent.length <= 0) {
                    return;
                }
                if (typeof contentLine[sortOrder] !== 'undefined') {
                    console.error(`There is already an item for the sort-order \`${sortOrder}\`!`);
                    return;
                }
                contentLine[sortOrder] = clsRowCellContent;
            });
            const $jsTable = $clsRow.closest(selector.TABLE);
            const $headingRow = $jsTable
                .find(`${selector.ROW}[data-structure="head"]`)
                .first();
            const csvTable = [[], []];
            let i;
            let $headingRowCell;
            for (i in contentLine) {
                if (Object.prototype.hasOwnProperty.call(contentLine, i)) {
                    csvTable[1].push(contentLine[i]);
                    $headingRowCell = $headingRow
                        .find(`${selector.CELL}[data-sort-order="${i}"]`)
                        .first();
                    csvTable[0].push($headingRowCell.length <= 0 ? '' : $headingRowCell.text().trim());
                }
            }
            const $nextMetaRowTabsFooter = $clsRow
                .next()
                .filter(selector.META_ROW)
                .find(`${selector.META_TAB} ${selector.META_FUNCTION_WRAPPER}[data-function-type="6"]`);
            if ($nextMetaRowTabsFooter.length) {
                csvTable.push([$nextMetaRowTabsFooter.text().trim()]);
            }
            let fileName = 'datarow.csv';
            if (typeof csvTable[1] === 'object'
                && typeof csvTable[1][0] === 'string'
                && csvTable[1][0].length) {
                fileName = `${csvTable[1][0]}.csv`;
            }
            csvTable.push([]);
            csvTable.push([
                `© SBB ${new Date().getFullYear()}`,
                'reporting.sbb.ch',
                'stat@sbb.ch',
            ]);
            const csvContent = this.getCSVContentFromArray(csvTable, ';', '\n');
            const $tmpForm = $('<form />');
            $tmpForm.attr('method', 'POST');
            $tmpForm.attr('action', downloadUrl);
            $tmpForm.append(`<input name="fileName" type="hidden" value="${fileName}">`);
            $tmpForm.append(`<input name="fileContent" type="hidden" value="${csvContent}">`);
            $('body').append($tmpForm);
            $tmpForm.trigger('submit');
            $tmpForm.remove();
        };
        this.onClickTableRowCollapse = event => {
            this.setEdited();
            const $elem = $(event.currentTarget);
            const $row = $elem.closest(selector.ROW);
            const id = $row.attr('data-id');
            const $childRows = $row.nextAll(`${selector.ROW}[data-parent="${id}"]`);
            const collapsed = $elem.attr('data-collapsed');
            let value = 'true';
            if (collapsed === 'true') {
                value = 'false';
            }
            if (value === 'true') {
                $row.next().attr('data-active', 'false');
                $row.find(selector.FUNCTION_TRIGGER).attr('data-active', 'false');
                $row.attr('data-function-active', 'false');
            }
            $childRows.each((index, element) => {
                const $childRow = $(element);
                if (value === 'true') {
                    $childRow
                        .find(`${selector.FUNCTION_TRIGGER}[data-active="true"]`)
                        .trigger('click');
                    $childRow
                        .find(`${selector.TABLE_ROW_COLLAPSE_TRIGGER}[data-collapsed="false"]`)
                        .trigger('click');
                    $childRow.next().attr('data-active', 'false');
                    $childRow.find(selector.FUNCTION_TRIGGER).attr('data-active', 'false');
                    $childRow.attr('data-function-active', 'false');
                }
                $childRow.attr('data-collapsed', value);
            });
            $row.attr('data-children-collapsed', value);
            $elem.attr('data-collapsed', value);
            if (this.$window.width() > Breakpoints.breakpointMobile)
                this.highlightVisibleOddRows();
            this.generateQueryString(false);
        };
        this.onClickEdit = event => {
            const $self = $(event.currentTarget);
            const edit = $self.attr('data-edit') === 'true' ? 'false' : 'true';
            this.$table.attr('data-edit', edit);
            $self.attr('data-edit', edit);
        };
        this.onClickYearVisibility = event => {
            this.setEdited();
            const $self = $(event.currentTarget);
            const selected = $self.attr('data-selected') === 'true' ? 'false' : 'true';
            const cellOrderId = $self.attr('data-sort-order');
            $self.attr('data-selected', selected);
            $(`${selector.CELL}[data-sort-order="${cellOrderId}"]`).attr('data-selected', selected);
            // Set all shadow elements to selected
            $(`${selector.YEAR_TRIGGER}[data-sort-order="${cellOrderId}"]`).attr('data-selected', selected);
            this.setTableStyleAndPositioningDependingOnScreenSize();
            this.generateQueryString(false);
        };
        this.onClickFunction = event => {
            const $self = $(event.currentTarget);
            const active = $self.closest(selector.ROW).attr('data-function-active') === 'true'
                ? 'false'
                : 'true';
            const id = `${$self.closest(selector.ROW).attr('data-id')}-meta`;
            $self.closest(selector.ROW).attr('data-function-active', active);
            if (active === 'true') {
                const $row = $self.closest(selector.ROW);
                const $metaRow = $(`[data-id="${$row.attr('data-id')}-meta"]`);
                const $barCharts = $metaRow.find(selector.BAR_CHART);
                if ($barCharts.length) {
                    this.initBarChart($barCharts);
                }
                const $lineCharts = $metaRow.find(selector.LINE_CHART);
                if ($lineCharts.length) {
                    this.initLineChart($lineCharts);
                }
                // $(SELECTOR_META_ROW + ' button:first-of-type').trigger('click'); //TODO: Removed to stop button(3dot hamburger) from triggering all share functions
            }
            $(`${selector.META_ROW}[data-id="${id}"]`).attr('data-active', active);
            if (this.$window.width() <= Breakpoints.breakpointMobile) {
                setTimeout(() => {
                    if (active === 'true') {
                        const $metaRow = $(`${selector.META_ROW}[data-id="${id}"] > td > div`);
                        const height = $metaRow.height();
                        const top = $metaRow.position().top;
                        $metaRow.css({
                            position: 'absolute',
                            top,
                            height: height - 1,
                        });
                        $(`${selector.META_ROW}[data-id="${id}"]`).css({
                            height,
                        });
                    }
                    else {
                        $(`${selector.META_ROW}[data-id="${id}"] > td > div`).removeAttr('style');
                    }
                }, 0);
            }
        };
        this.onClickSave = event => {
            this.$table.attr('data-edit', 'false');
            $(selector.SAVE_TRIGGER).attr('data-edited', 'false');
            this.refreshTableLoadingState(true);
            $(event.target).attr('data-loading', 'true');
            const remote = $(event.target).attr('data-remote') === 'true';
            const openIDs = [];
            const openYears = [];
            $(`${selector.ROW}[data-collapsed="false"]`).each((index, element) => {
                openIDs.push($(element).attr('data-id'));
            });
            $(`${selector.ROW}:first-of-type ${selector.CELL}[data-selected="true"]`).each((index, element) => {
                openYears.push($(element).attr('data-sort-order'));
            });
            const cbDone = () => setTimeout(() => {
                this.refreshTableLoadingState(false);
                $(event.target).attr('data-loading', 'false');
            }, 1000);
            $('*[for="tableYearTrigger"]').trigger('click');
            if (!remote) {
                this.Storage.set(this.sheetId, {
                    years: openYears,
                    rows: openIDs,
                });
                return cbDone();
            }
            const serviceURL = this.$table.attr('data-set-default-layout-service-url');
            return $.ajax({
                url: serviceURL,
                type: 'POST',
                data: { sheet: this.sheetId, columns: openYears, rows: openIDs },
                dataType: 'json',
            })
                .done(() => {
                /* eslint-disable-next-line */
                console.info('Successfully saved default view');
                return cbDone();
            })
                .fail(err => {
                console.error('Could not save the default view');
                console.error('response', err.responseText);
                return cbDone();
            });
        };
        this.onClickTab = event => {
            const parentId = $(event.target).attr('data-parent-id');
            const id = $(event.target).attr('data-id');
            $(`${selector.META_TAB_TRIGGER}[data-parent-id="${parentId}"][data-selected="true"]`)
                .attr('data-selected', 'false');
            $(`${selector.META_TAB}[data-parent-id="${parentId}"][data-selected="true"]`)
                .attr('data-selected', 'false');
            $(event.target).attr('data-selected', 'true');
            $(`${selector.META_TAB}[data-id="${id}"][data-parent-id="${parentId}"]`)
                .attr('data-selected', 'true');
        };
        this.onPrint = () => {
            const $triggerPrint = $(selector.PRINT_TRIGGER);
            $triggerPrint.attr('data-loading', 'true');
            const url = window.location.href.substring(window.location.origin.length);
            const newHref = `/_common/pageprint?url[]=${encodeURIComponent(url)}`;
            $triggerPrint.attr('data-loading', 'false');
            toastr.options.closeButton = true;
            toastr.options.timeOut = 100000;
            toastr.success(window.sv_resource.get('pdf_generation_success'));
            // location.href = newHref;
            const win = window.open(newHref, '_blank');
            setTimeout(() => {
                win.focus();
            }, 3000);
        };
        this.onWindowResize = () => {
            //  prevent resizing when browser ctrls are showing up and dissapearing
            if (this.$window.width() !== this.width) {
                this.width = this.$window.width();
                this.setTableStyleAndPositioningDependingOnScreenSize();
                this.$window.trigger('fixTableHead');
            }
        };
        this.onTableExpand = () => {
            this.setEdited();
            $(selector.TABLE_ROW_COLLAPSE_TRIGGER).not('[data-collapsed="false"]').trigger('click');
        };
        this.onTableScroll = event => {
            const $wrapper = $(event.currentTarget);
            const $head = $(`${selector.ROW}[data-structure="head"][data-fixed="true"]`);
            const scroll = `${$wrapper.scrollLeft() * -1}px`;
            $head.find(`${selector.CELL}:not(.js-is-absolute)`).css({
                '-ms-transform': `translateX(${scroll})`,
                '-webkit-transform': `translateX(${scroll})`,
                transform: `translateX(${scroll})`,
            });
        };
        this.onWindowScroll = () => {
            this.waitForRefresh = 0;
            this.$window.trigger('fixTableHead');
        };
        this.onFixTableHead = () => {
            const $head = $(`${selector.ROW}[data-structure="head"]`);
            if ($head.length) {
                const headerHeight = document.querySelector('.o-header__wrapper').clientHeight; // Navbar height in px
                let editBarHeight = null; // edit bar does not exist on mobile
                if (headerHeight < Breakpoints.breakpointMobile) {
                    editBarHeight = document.querySelector('.plf-zafa-head').clientHeight;
                }
                const headingoffset = $head.offset().top - this.$window.scrollTop();
                const state = $head.attr('data-fixed');
                const tableoffset = $(selector.TABLE).offset().top - this.$window.scrollTop();
                const tableHeight = $(selector.TABLE).height();
                if (headingoffset <= headerHeight && state !== 'true') {
                    if (this.$window.width() > Breakpoints.breakpointMobile) {
                        const $zafaHead = $('.plf-zafa-head');
                        $zafaHead.width($(selector.TABLE).width());
                        $zafaHead.attr('data-fixed', 'true');
                        $zafaHead.css({
                            position: 'fixed',
                            display: 'table',
                            left: 'auto',
                            right: 'auto',
                            margin: '0 auto',
                            top: headerHeight,
                            'z-index': 2,
                        });
                    }
                    else {
                        $('.js-table-wrapper').css('padding-top', 60);
                    }
                    $head.find(selector.ABSOLUTE_CELL).each((index, cell) => {
                        $(cell).css('margin-left', 0);
                    });
                    $head
                        .find(`${selector.CELL}:not([data-selected="false"])`)
                        .each((index, cell) => {
                        const outerWidth = $(cell).outerWidth(true);
                        $(cell).css({
                            'max-width': 'none',
                            width: outerWidth,
                        });
                    });
                    $head.width($(selector.TABLE).width());
                    $head.attr('data-fixed', 'true');
                    const tableHeadTopDistance = headerHeight + editBarHeight;
                    $head.css({
                        position: 'fixed',
                        display: 'table',
                        left: 'auto',
                        right: 'auto',
                        margin: '0 auto',
                        top: tableHeadTopDistance,
                        'z-index': 1,
                    });
                    $(selector.TABLE_WRAPPER).trigger('scroll');
                }
                else if ((tableoffset > headerHeight && state !== 'false') || (tableoffset + tableHeight < 0 && state !== 'false')) {
                    $head.attr('data-fixed', 'false');
                    const $zafaHead = $('.plf-zafa-head');
                    $zafaHead.attr('data-fixed', 'false');
                    $head
                        .find(`${selector.CELL}:not([data-selected="false"]):not(.js-is-absolute)`)
                        .each((index, cell) => {
                        $(cell).removeAttr('style');
                    });
                    $head.removeAttr('style');
                    $zafaHead.removeAttr('style');
                    $('.js-table-wrapper').removeAttr('style');
                }
            }
        };
        this.setEdited = () => $(selector.SAVE_TRIGGER).attr('data-edited', 'true');
        this.initEvents = () => {
            $(selector.EDIT).on('click', this.onClickEdit);
            $(selector.PRINT_TRIGGER).on('click', this.onPrint);
            $(selector.SAVE_TRIGGER).on('click', this.onClickSave);
            $(selector.EXPAND_TABLE).on('click', this.onTableExpand);
            $(selector.META_TAB_TRIGGER).on('click', this.onClickTab);
            $(selector.FUNCTION_TRIGGER).on('click', this.setDeepLink);
            $(selector.FUNCTION_TRIGGER).on('click', this.onClickFunction);
            $(selector.YEAR_TRIGGER).on('click', this.onClickYearVisibility);
            $(selector.ROW_DOWNLOAD_TRIGGER).on('click', this.onClickRowDownload);
            $(selector.TABLE_ROW_COLLAPSE_TRIGGER).on('click', this.onClickTableRowCollapse);
            $(`${selector.TABLE_WRAPPER}[data-scroll="true"]`).on('scroll', this.onTableScroll);
            this.$window.on('resize', this.onWindowResize);
            this.$window.on('fixTableHead', this.onFixTableHead);
            this.$window.on('scroll', this.onWindowScroll);
            this.setRefreshIntervall();
            this.triggerInitialTableHeadFixing();
        };
        this.initialize = () => {
            const isPdf = this.getUrlParameter('printpdf', false);
            this.highlightedId = this.getUrlParameter('highlighted', null);
            if (!isPdf) {
                this.Storage.prefix = 'zafa_';
                this.initLocalConfig(false);
                this.setTableStyleAndPositioningDependingOnScreenSize();
                this.highlightVisibleOddRows();
                this.initHeadingRowCollapseState();
                this.initNormalRowCollapseState();
                if (this.highlightedId === null) {
                    this.initScrollPos();
                }
            }
            else {
                try {
                    this.$table.attr('data-loading', 'false');
                    this.initLocalConfig(true);
                    this.highlightVisibleOddRows();
                    this.initHeadingRowCollapseState();
                    this.initNormalRowCollapseState();
                    if (this.highlightedId === null) {
                        this.initScrollPos();
                    }
                    this.markLastVisibleCell();
                    this.addTableHeaders();
                    const year = $(`${selector.ROW}:first`)
                        .find('*[data-type="value"][data-selected="true"]')
                        .last()
                        .text();
                    const $addDate = $('*[data-add-date]');
                    $addDate.text($addDate.text() + year);
                }
                catch (e) {
                    $('body').prepend(`<p style="color: #f00; font-size: 16px; font-weight: bold;">${e.message}</p>`);
                }
            }
            this.initEvents();
            setTimeout(() => {
                this.PdfPrinter.onDone(this.pdfPrintingAppId);
            }, 800);
        };
        this.Storage = Storage;
        this.PdfPrinter = PdfPrinter;
        this.pdfPrintingAppId = this.PdfPrinter.register();
        this.$table = $(selector.TABLE);
        this.$window = $(window);
        this.width = this.$window.width();
        this.sheetId = parseInt(this.$table.attr('data-sheet'), 10);
        this.language = navigator.language || 'de';
    }
}
